<template>
  <el-dialog v-model="dialogVisible" title="修改姓名" width="500px">
    <el-form
      ref="checkForm"
      :model="formvalue"
      :rules="rules"
      label-width="4rem"
    >
      <el-form-item label="姓名" prop="nickName">
        <el-input v-model="formvalue.nickName" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submit()">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, defineExpose, defineEmits } from 'vue'
import { alterInfo } from '@/api/user/index'
import { ElMessage } from 'element-plus'

const checkForm = ref(null)
const dialogVisible = ref(false)
const emits = defineEmits(['uppage'])

const formvalue = ref({
  nickName: ''
})

const rules = ref({
  nickName: [
    {
      required: true,
      message: '请输入姓名',
      trigger: ['change', 'blur']
    },
    {
      min: 2,
      max: 5,
      message: '最多输入2至5个',
      trigger: 'blur'
    }
  ]
})

const submit = () => {
  checkForm.value.validate((value) => {
    if (value) {
      alterInfo(formvalue.value).then((res) => {
        if (res.code === 200) {
          ElMessage({
            message: '修改成功',
            type: 'success'
          })
          dialogVisible.value = false
          emits('uppage')
        }
      })
    }
  })
}
const setParams = () => {
  dialogVisible.value = true
}

defineExpose({
  setParams
})
</script>
<style lang="scss" scoped></style>
