<template>
  <div class="AnsweringQuestion">
    <!-- 搜索 -->
    <el-form :inline="true" :model="queryValue" class="demo-form-inline" >
      <el-form-item label="课程名称" >
        <el-input v-model="queryValue.courseName" placeholder="输入课程名称" />
      </el-form-item>
      <el-form-item label="课时名称">
        <el-input v-model="queryValue.periodName" placeholder="输入课时名称" />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="queryValue.creaTime"
          type="date"
          placeholder="选择时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button class="zsbtn" @click="getlist">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 内容 -->
    <el-table :data="tableData" stripe style="width: 100%" >
      <el-table-column prop="createTime" label="创建时间"    width="200"   align="center" />
      <el-table-column prop="courseName" label="课程名称"       align="center"/>
      <el-table-column prop="periodName" label="课时名称"       align="center"/>
      <el-table-column label="是否回复" width="150"      align="center">
        <template #default="scope">
          {{ scope?.row?.proState == 1 ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150"      align="center">
        <template #default="scope">
          <div v-if="scope.row.issee=='1'"  @click="LookOver(scope.row)">查看</div>
          <div v-if="scope.row.issee=='0'" style=" color: #f85959;"  @click="LookOver(scope.row)">查看</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="row juc">
      <el-pagination
        class="ma-tb-50"
        layout="prev, pager, next"
        @current-change="alterTotal"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="dialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <template #title>
        <div class="row aic">
          <div class="title">{{ showCon.chapterName }}</div>
          <div class="subtitle" style="margin-left: 1em">
            {{ showCon.periodName }}
          </div>
        </div>
      </template>
      <div class="dialogBox col">
        <div :class="d.proType ? 'wo' : 'ta'" v-for="d in content" :key="d.id">
          <div class="msg" v-html="d.content"></div>
          <div class="time">
            {{ d.createTime }}
            <!-- <span v-if="d.duration">
              <el-icon size="20" color="#293E73"
                ><video-camera-filled
              /></el-icon>
              {{
                moment.duration(d.duration || 0, 'seconds').format('HH:mm:ss')
              }}</span
            > -->
            <el-button v-if="d.duration" size="small" style="margin-left: 10px;"  :icon="CaretRight"  round>
               {{
                moment.duration(d.duration || 0, 'seconds').format('HH:mm:ss')
              }}
            </el-button>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="reply row">
          <el-input
            class="inpText"
            v-model="msgInput"
            placeholder="请输入..."
            clearable
          />
          <el-button @click="send" class="zsbtn bo-ra-50">回复</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { getQuizList, getQuizDetail, addQuestion } from '@/api/user/index'
import { useStore } from 'vuex'
// 图标
import { VideoCameraFilled ,CaretRight } from '@element-plus/icons'
momentDurationFormatSetup(moment)
const store = useStore()
const queryValue = ref({
  // issee: 1
})
const msgInput = ref('')
const tableData = ref([])
const showCon = ref([])
const total = ref(0)
const dialogVisible = ref(false)
const content = ref({})

const getlist = () => {
  if (queryValue.value.creaTime) {
    queryValue.value.creaTime = moment(queryValue.value.creaTime).format(
      'YYYYMMDD'
    )
  }
  getQuizList(queryValue.value).then((res) => {
    tableData.value = res.rows
    total.value = res.total
    store.commit('msg/setQuestion', false)
  })
}
getlist()
const alterTotal = (pageNum) => {
  queryValue.value.pageNum = pageNum
  getlist()
}
// 重置
const reset = () => {
  queryValue.value = {}
}
// 查看
const LookOver = (v) => {
  v.issee='1'
  showCon.value = v
  getQuizDetail({ proId: v.id, issee: 1 ,sort:1}).then((res) => {
    content.value = res.rows
    dialogVisible.value = true
  })
}
const handleClose = () => {
  dialogVisible.value = false
}
// 发送
const send = () => {
  if (!msgInput.value) {
    return
  }
  addQuestion({ ...showCon.value, content: msgInput.value }).then((res) => {
    content.value.push({
      ...showCon.value,
      content: msgInput.value,
      proType: 1
    })
    msgInput.value = ''
  })
}
</script>
<style lang="scss" scoped>
@import '@/common/rem';
  .el-badge__content.is-fixed.is-dot{
      top: 5px !important;
  }
.AnsweringQuestion {
  .el-table__row .el-table__row--striped {
    background: #f3f5f7;
  }
  .zsbtn {
    background: #293e73;
    color: white;
  }
  .dialogBox {
    font-family:"Microsoft YaHei";
    padding: rem(20);
    height: rem(45vh);
    border-radius: rem(10);
    overflow-x: auto;
    background: #f3f5f7;
    .ta,
    .wo {
      // font-weight: 600;
      line-height: 1.5;
      margin: rem(10) auto rem(10) 0;
      max-width: rem(468);
      display: flex;
      flex-direction: column;
      .msg {
        color: #ffffff;
        border-radius: rem(10);
        padding: rem(10) rem(15);
        background: #6e7eb8;
        margin-bottom: 3px;
      }
    }
    .wo {
      margin: rem(10) 0 rem(10) auto;
      .msg {
        color: #333;
        background: #ffffff;
      }
      .time {
        text-align: right;
      }
    }
  }
  .reply {
    .inpText,
    ::v-deep .el-input input {
      width: 100%;
      height: rem(50);
      border-radius: rem(25);
      background: #f3f5f7;
    }
    .zsbtn {
      width: rem(120);
      height: rem(50);
      margin-left: rem(20);
    }
  }
  ::v-deep .el-dialog {
    border-radius: 20px;
  }
}
</style>
