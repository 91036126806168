import moment from 'moment'
export function exportFile(data, name, type) {
  let link = document.createElement('a')
  // type就是blob的type,是MIME类型的
  let blogw = new Blob([data], { type })
  let objectUrl = window.URL.createObjectURL(blogw) // 创建一个新的url对象
  link.href = objectUrl
  let file_name = `${name}__${moment().format('YYYY-MM-DD HH:mm:ss')}`
  link.download = file_name // 下载的时候自定义的文件名
  link.click()
  window.URL.revokeObjectURL(objectUrl) // 为了更好地性能和内存使用状况，应该在适当的时候释放url.
}
