<template>
  <el-dialog v-model="dialogVisible" title="修改邮箱" width="500px">
    <el-form
      ref="checkForm"
      :model="alterParam"
      :rules="rules"
      label-width="7rem"
    >
      <el-form-item label="请输入邮箱" prop="addressee">
        <el-input style="width: 244px;" v-model="alterParam.addressee"></el-input>
        <auth-code
          text="发送"
          ref="code"
          class="auth-code"
          btnClass="code-btn"
          second="60"
          @start="serdCode(alterParam.addressee)"
        ></auth-code>
      </el-form-item>
      <el-form-item label="请输入验证码" prop="eamilCode">
        <el-input v-model="alterParam.eamilCode" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submit()">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, defineExpose, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import { getEmailCodeEnd, checkEmail } from '@/api/user/index'
import { regexEmail } from '@/common/regexp'
import AuthCode from '@/components/AuthCode'
import { useStore } from 'vuex'
const dialogVisible = ref(false)
const store = useStore()
const checkForm = ref(null)
const alterParam = ref({
  addressee: '',
  eamilCode: ''
})
const emits = defineEmits(['uppage'])

const rules = ref({
  addressee: [
    {
      required: true,
      message: '请输入邮箱',
      trigger: ['change', 'blur']
    },
    {
      validator: (rule, val, callback) => {
        regexEmail.test(val) ? callback() : callback(new Error('邮箱格式错误'))
      },
      trigger: ['change']
    }
  ],
  eamilCode: [
    {
      required: true,
      message: '请输入验证码',
      trigger: ['change', 'blur']
    }
  ]
})

const serdCode = async (addressee) => {
  const res = await getEmailCodeEnd({ addressee })
  if (res.data) {
    ElMessage({
      message: '发送成功',
      type: 'success'
    })
  }
}

const submit = () => {
  checkForm.value.validate((value) => {
    if (value) {
      checkEmail({
        ...alterParam.value,
        userNo: store.getters.userInfo.userId
      }).then((res) => {
        if (res.code === 200) {
          ElMessage({
            message: '修改成功',
            type: 'success'
          })
          dialogVisible.value = false
          emits('uppage')
        }
      })
    }
  })
}
const setParams = () => {
  dialogVisible.value = true
}
defineExpose({
  setParams
})
</script>
<style lang="scss">
@import '@/common/rem';
.auth-code {
  margin: auto;
  right: rem(0em);
  position: absolute;
  top: calc(50% - 1.1em);
  .code-btn {
    width: auto;
    padding: 5 20px;
    color: #293e73;
    background: #ffffff;
  }
}
</style>
