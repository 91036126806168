<template>
  <div class="ma-tb-30 row jue">
    <div class="tabbtn">
      <span
        :class="{ acative: queryParams.orderStatus == 2 }"
        class="pointer"
        @click="setOrderStatic(2)"
        >已完成订单</span
      >
      |
      <span
        :class="{ acative: queryParams.orderStatus == 0 }"
        class="pointer"
        @click="setOrderStatic(0)"
        >未完成订单</span
      >
    </div>
  </div>
  <div class="order-list col">
    <div v-if="orderList.length > 0">
      <div v-for="item in orderList" :key="item.id" class="order-list-item row">
        <div class="order-number">订单号：{{ item.orderNo }}</div>
        <div class="order-info">
          <div class="subtitle">{{}}</div>
          <div class="importance row sb">
            <div class="sonwin" >
              <div>课程名称</div>
              <div>{{ item.courseName }}</div>
            </div>
            <div class="sonwin" >
              <div>订单价格</div>
              <div>￥{{ item.pricePaid || '免费' }}</div>
            </div>
            <div class="sonwin" >
              <div>下单方式</div>
              <div>
                <!-- 1网站下单 2app下单 0线下 -->
                {{
                  item.payType
                    ? '网站下单'
                    : item.payType === 0
                    ? '线下'
                    : item.payType === 2
                    ? 'app'
                    : '未知'
                }}
              </div>
            </div>
            <div class="sonwin" >
              <div>支付时间</div>
              <div>{{ item.createTime || '无' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-100 text-center ma-tb-50">
      暂无订单{{ orderStatic[queryParams.orderStatus - 1] }}~
      <!-- 1待支付，2成功支付，3支付失败，4已关闭 5待审核 -->
    </div>
  </div>
  <div class="row juc">
    <el-pagination
      class="ma-tb-30"
      layout="prev, pager, next"
      :total="total"
      :page-size="queryParams.pageSize"
      @current-change="alterTotal"
      v-if="total"
    >
      ></el-pagination
    >
  </div>
</template>
<script setup>
import { ref } from '@vue/reactivity'
import { getOrderList } from '@/api/user/index'
const orderList = ref([])
const orderStatic = ref(['待支付', '成功支付', '支付失败', '已关闭', '待审核'])
const total = ref(0)
const queryParams = ref({
  orderStatus: 2,
  pageNum: 1,
  pageSize: 9
})
const setOrderStatic = (sta) => {
  queryParams.value.orderStatus = sta
  getList()
}

const getList = () => {
  getOrderList(queryParams.value).then((res) => {
    orderList.value = res.rows
    total.value = res.total
  })
}
const alterTotal = (pageNum) => {
  queryParams.value.pageNum = pageNum
  getList()
}
setOrderStatic(2)
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.tabbtn {
  font-weight: 900;
  .acative {
    color: #293e73;
  }
}
.importance {
  font-size: 14px;
  // font-weight: bold;
  color: #333333;
}
.order-list {
  .order-list-item {
    padding: rem(20);
    overflow: hidden;
    position: relative;
    border-radius: rem(10);
    background: #f3f5f7;
    margin-bottom: rem(20);
    .order-number {
      right: 0;
      top: 0;
      padding: rem(10) rem(20);
      font-size: rem(12);
      position: absolute;
      color: #ffffff;
      background: #293e73;
      border-radius: 0 rem(20);
    }
    .order-info {
      width: 75%;
      .importance {
        div {
          > div {
            margin-bottom: rem(10);
            text-align: center;
          }
        }
      }
      .sonwin {
        width: 25%;
      }
    }
  }
}
</style>
