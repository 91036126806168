<template>
  <el-dialog
    v-if="!isadd"
    v-model="dialogVisible"
    title="修改手机号"
    width="500px"
  >
    <el-form
      ref="checkForm"
      :model="alterParam"
      :rules="rules"
      label-width="7rem"
    >
      <el-form-item label="请输入手机号" prop="phonenumber">
        <el-input style="width: 244px;" v-model="alterParam.phonenumber"></el-input>
        <auth-code
          text="发送验证码"
          ref="code"
          class="auth-code"
          btnClass="code-btn"
          second="60"
          @start="serdCode(alterParam.phonenumber)"
        ></auth-code>
      </el-form-item>
      <el-form-item label="请输入验证码" prop="code">
        <el-input v-model="alterParam.code" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submit()">修改</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-else v-model="dialogVisible" title="添加手机号" width="500px">
    <el-form
      ref="adddform"
      :model="alterParam"
      :rules="rules"
      label-width="7rem"
    >
      <el-form-item label="请输入手机号" prop="phonenumber">
        <el-input style="width: 244px;" v-model="alterParam.phonenumber"></el-input>
        <auth-code
          text="发送验证码"
          ref="code"
          class="auth-code"
          btnClass="code-btn"
          second="60"
          @start="serdCode(alterParam.phonenumber)"
        ></auth-code>
      </el-form-item>
      <el-form-item label="请输入验证码" prop="code">
        <el-input v-model="alterParam.code" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submit()">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineExpose, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import { alterInfo } from '@/api/user/index'
import { getPhoneCode, checkPhoneCode } from '@/api/login'
import { regexPhone } from '@/common/regexp'
import AuthCode from '@/components/AuthCode'
import { useStore } from 'vuex'

const store = useStore()
const emits = defineEmits(['uppage'])
const dialogVisible = ref(false)
const checkForm = ref(null)
const alter = ref(false)
const isadd = ref(false)
const alterParam = ref({
  phonenumber: '',
  code: ''
})
const adddform = ref(null)
const rules = ref({
  phonenumber: [
    {
      required: true,
      message: '请输入手机号',
      trigger: ['change', 'blur']
    },
    {
      validator: (rule, val, callback) => {
        regexPhone.test(val)
          ? callback()
          : callback(new Error('手机号格式错误'))
      },
      trigger: ['change']
    }
  ],
  code: [
    {
      required: true,
      message: '请输入验证码',
      trigger: ['change', 'blur']
    }
  ]
})

const serdCode = async (phonenumber) => {
  if (regexPhone.test(phonenumber)) {
    const res = await getPhoneCode({ phone: phonenumber })
    if (res.data) {
      ElMessage({
        message: '发送成功',
        type: 'success'
      })
    }
  } else {
    ElMessage({
      message: '手机号格式不正确',
      type: 'error'
    })
  }
}
const check = () => {
  checkForm.value.validate((value) => {
    checkPhoneCode(alterParam.value).then((res) => {
      if (res.data) {
        alter.value = true
      } else {
        ElMessage({
          message: '验证码不正确',
          type: 'error'
        })
      }
    })
  })
}

const submit = () => {
  if (isadd.value) {
    checkPhoneCode({
      phone: alterParam.value.phonenumber,
      code: alterParam.value.code
    }).then((res) => {
      if (res.data) {
        alterInfo({ phonenumber: alterParam.value.phonenumber }).then((res) => {
          if (res.code === 200) {
            ElMessage({
              message: '设置成功',
              type: 'success'
            })
            dialogVisible.value = false
          }
        })
      } else {
        ElMessage({
          message: '验证失败',
          type: 'error'
        })
      }
    })
  } else {
    alterInfo({ phonenumber: alterParam.value.phonenumber }).then((res) => {
      dialogVisible.value = false
    })
  }
  if (!dialogVisible.value) {
    emits('uppage')
  }
}

const reset = () => {
  alterParam.value = {
    phonenumber: '',
    code: ''
  }
}
const setParams = () => {
  reset()
  isadd.value = false
  dialogVisible.value = true
}
const add = () => {
  reset()
  isadd.value = true
  dialogVisible.value = true
}
defineExpose({
  setParams,
  add
})
</script>
<style lang="scss">
@import '@/common/rem';
.auth-code {
  margin: auto;
  width: 6rem !important;
  right: rem(0em);
  position: absolute;
  top: calc(50% - 1.1em);
  .code-btn {
    width: auto;
    padding: 5 20px;
    color: #293e73;
    background: #ffffff;
  }
}
</style>
