<template>
  <div class="msg-list col">
    <div v-if="msgList.length > 0">
      <div class="list-item row" v-for="(item, index) in msgList" :key="index">
        <div class="time">
          <div class="day">{{ moment(item.createTime).format('DD') }}</div>
          <div class="years">
            {{ moment(item.createTime).format('YYYY-MM') }}
          </div>
        </div>
        <div style="padding-left: 20px ;font-family:'Microsoft YaHei'">
          <div class="title">{{ item.noticeTitle }}</div>
          <div class="content" v-html="item.noticeContent"></div>
        </div>
      </div>
    </div>
    <div v-else class="ma-tb-50 text-center">暂无消息</div>
  </div>
  <div class="row juc">
    <el-pagination
      class="ma-tb-50"
      layout="prev, pager, next"
      v-if="total > 0"
      :total="total"
      :page-size="queryParams.pageSize"
      @current-change="alterTotal"
    ></el-pagination>
  </div>
</template>
<script setup>
import { ref } from '@vue/reactivity'
import { getNoticeList } from '@/api/user/index'
import moment from 'moment'
import { useStore } from 'vuex'
const store = useStore()
const queryParams = ref({
  pageNum: 1,
  pageSize: 10,
  // userType: 1,
  issee: 1
})
const msgList = ref([])
const total = ref(0)
const getList = () => {
  getNoticeList(queryParams.value).then((res) => {
    msgList.value = res.rows || []
    total.value = res.total
    store.commit('msg/setMsg', false)
  })
}
const alterTotal = (pageNum) => {
  queryParams.value.pageNum = pageNum
  getList()
}
getList()
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.msg-list {
  .list-item {
    padding: rem(20);
    margin-top: rem(10);
    border-radius: rem(10);
    background: #f3f5f7;

    .time {
      font-weight: bold;
      text-align: center;
      font-size: rem(14);
      font-family: Arial;
      color: #afafaf;
      padding-right: rem(20);
      border-right: rem(1) solid #cccccc;
      .day {
        font-size: rem(60);
      }
    }
    .content {
      font-size: rem(14);
      font-weight: 400;
      line-height: 1.5;
      color: #666666;
    }
  }
}
</style>
