<template>
  <div class="account ma-tb-30">
    <!-- <div class="title text-center ma-tb-60"> -->
      <!-- 欢迎您加入六问学习平台。你的努力终将成就无可替代的自己！ -->
      <!-- 同学你好！欢迎加入六问课堂，成就更好的自己。 -->
    <!-- </div> -->
    <div class="account-info col sb">
      <el-form  size="large">
        <div class="titleinfo" >学习信息</div>
        <el-form-item  label="记录笔记"
          ><spen>{{ info?.noteNum || 0 }}</spen>
            <el-divider />
        </el-form-item>
        
        <el-form-item label="发表答疑"
          >{{
            info?.problemNum || 0
          }}   <el-divider /></el-form-item
        >
        <el-form-item label="学习时长"
          >{{ statistics.allNum || 0 }}
             <el-divider />
        </el-form-item>
        <el-form-item label="完成课程"
          >{{
            statistics.classNum || 0
          }}   
          <el-divider />
          </el-form-item
        >
          <el-form-item label="我的学分"
          >
          {{ info?.fraction || 0 }}
                   <el-divider />
          </el-form-item
        >
        <div class="titleinfo" >账号信息</div>
        <el-form-item label="手机号码"
          >{{
            info.phonenumber || '未设置手机号'
          }}
              <el-button type="text"  @click="alterphone()" class="shezhi">设置</el-button>
            <el-divider />
        </el-form-item>
            <el-form-item label="微信账号"
          >
          {{ info.unionid ? '已绑定' : '未设置微信账号' }}
        <el-button type="text"  @click="alterwx()" class="shezhi">设置</el-button>
           <el-divider />
           </el-form-item>
        <el-form-item label="邮箱地址"
          >{{ info?.email || '未设置邮箱' }}
        <el-button type="text"  @click="alteremail()" class="shezhi">设置</el-button>
           <el-divider />
           </el-form-item>
        <el-form-item label="修改密码"
          >
        修改密码
        <el-button type="text"  @click="alterpass()" class="shezhi">设置</el-button>
           <el-divider />
           </el-form-item>
          <el-form-item label="注销账号">
        我要注销
        <el-button type="text"   @click="dialogVisible = true" class="shezhi">设置</el-button>
           <el-divider />
           </el-form-item>
               <div class="titleinfo" >基本信息</div>
         <el-form-item label="学员姓名"
          >{{ info?.nickName }}
                   <el-divider />
        </el-form-item>
        <el-form-item label="注册时间"
          >
          {{ info.createTime }}
                   <el-divider />
        </el-form-item>
          <el-form-item label="教务老师"
          >
          {{ info?.registrar || '无' }}
               <el-divider />
          </el-form-item
        >
          <div class="row jue">
    <!-- <el-button type="danger" @click="dialogVisible = true">账号注销</el-button> -->
  </div>
      </el-form>
    <el-dialog
      v-model="dialogVisible"
      title="账号注销"
      width="30%"
      center='true'
    >
      <span>请您在注销账号前，充分阅读并同意下列事项：</span><br/>
      <span>1.您的账号处于正常使用状态，未发生过被盗、被禁封等其他账号异常情形。</span><br/>
      <span>2.账号已解除与第三方关系或授权登录关系。</span><br/>
      <span>3.账号未涉及任何争议纠纷</span><br/>
      <span>4.您的账号一旦被注销成功将不可恢复</span><br/>
      <span>5.注销账号并不代表账号注销之前的行为和相关责任得到任何形式的豁免或减轻。</span><br/>
      <span>6.注销成功后，我们将限期删除您的个人信息或对其进行匿名化处理。请您知悉并理解，根据相关法律法规规定，六问课堂将就相关日志记录保留不少于6个月的时间。</span><br/>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary"  @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" plain @click="del" 
            >注销</el-button
          >
        </span>
      </template>
    </el-dialog>
    </div>
  </div>
  <div style="height: 1px"></div>
  <alteration-phone-vue ref="phone" @uppage="onUppage"></alteration-phone-vue>
  <alteration-wx-vue ref="wx" @uppage="onUppage"></alteration-wx-vue>
  <alteration-email-vue ref="email" @uppage="onUppage"></alteration-email-vue>
  <alteration-pass-vue ref="pass"></alteration-pass-vue>
  <alteration-info-vue ref="userinfo" @uppage="onUppage"></alteration-info-vue>
</template>
<script setup>
import { ref, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { ElMessageBox, ElMessage } from 'element-plus'
import { delaccount, getStatistics } from '@/api/user'
import { Edit, Tickets, Loading } from '@element-plus/icons'
import alterationPhoneVue from './components/alterationPhone.vue'
import alterationWxVue from './components/alterationWx.vue'
import alterationEmailVue from './components/alterationEmail.vue'
import alterationPassVue from './components/alterationPass.vue'
import alterationInfoVue from './components/alterationInfo.vue'
const phone = ref(null)
const wx = ref(null)
const email = ref(null)
const pass = ref(null)
const userinfo = ref(null)
const dialogVisible = ref(false)
const store = useStore()
const iconColor = ref('')
const iconSize = ref(16)
const info = ref({})
store.dispatch('user/getUserInfo')
info.value = store.getters.userInfo
const alterphone = () => {
  if (info.value.phonenumber) {
    phone.value.setParams()
  } else {
    ElMessageBox.confirm('当前账号未绑定手机号，是否绑定手机号？', '系统提示', {
      confirmButtonText: '绑定手机号',
      cancelButtonText: '取消'
    }).then((res) => {
      if (res === 'confirm') {
        phone.value.add()
      }
    })
  }
}
const statistics = ref({})
getStatistics(info.value.userId).then((res) => {
  statistics.value = res.data
})
const alterwx = () => {
  if (info.value.unionid) {
    wx.value.setParams()
  } else {
    ElMessageBox.confirm('当前账号未绑定微信号，是否绑定微信号？', '系统提示', {
      confirmButtonText: '绑定微信号',
      cancelButtonText: '取消'
    }).then((res) => {
      if (res === 'confirm') {
        wx.value.setParams()
      }
    })
  }
}
const alteremail = () => {
  if (info.value.email) {
    email.value.setParams()
  } else {
    ElMessageBox.confirm('当前账号未绑定邮箱，是否绑定邮箱？', '系统提示', {
      confirmButtonText: '未绑定邮箱',
      cancelButtonText: '取消'
    }).then((res) => {
      if (res === 'confirm') {
        email.value.setParams()
      }
    })
  }
}
const alterpass = () => {
  pass.value.setParams()
}
watchEffect(() => {
  info.value = store.getters.userInfo
})
const onUppage = async () => {
  await store.dispatch('user/getUserInfo')
}
const del = () => {
        delaccount().then((res) => {
        store.dispatch('user/logout')
      })
  // ElMessageBox.confirm(
  //   "确请您在注销账号前，充分阅读并同意下列事项：1.您的账号处于正常使用状态，未发生过被盗、被禁封等其他账号异常情形。2.账号已解除与第三方关系或授权登录关系。",
  //   '系统提示',
  //   {
  //     confirmButtonText: '注销',
  //     cancelButtonText: '取消',
  //     type: 'error'
  //   }
  // ).catch((res) => {
  //   if (res == 'confirm') {
  //     delaccount().then((res) => {
  //       store.dispatch('user/logout')
  //     })
  //   }
  // })
}
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.account {
  line-height: 2;
  padding: rem(0) 0;
  border-radius: 10px;
  // background: #f3f5f7;
  .title {
    font-size: 28px;
    color: #000;
    // font-family: '楷体 行楷 形体 小篆 宋体 仿宋';
  }
  .account-info {
    padding: 20px;
    flex-wrap: wrap;
    > div {
      width: calc(33% - 40px);
      display: flex;
      flex-direction: column;
      align-content: center;
      vertical-align: middle;
      padding: 0 20px;
      box-sizing: border-box;
      border-right: 0.2rem solid #fff;
    }
  }
  .el-form-item {
    // width: 50%;
    margin: 0 !important;
  }
  ::v-deep .el-form-item__content {
    font-size: 16px;
    // font-weight: 900;
    padding-left: 2rem;
  }
}
.el-form-item--large{
  --font-size: 17px;
}
.el-divider--horizontal{
  margin: 20px 0;
}
.titleinfo{
  font-size: 20px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 40px;
    margin-top: 20px;
}
.shezhi{
  position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    text-align: right;
    font-size: 16px;
}
.el-dialog__header{
  text-align: center;
}
.el-dialog__title{
  font-weight: 600;
}
</style>
