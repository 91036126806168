<template>
  <el-dialog v-model="dialogVisible" title="修改微信" width="500px">
    <div class="aic col">
      <div>请使用要修改的微信扫码</div>
      <qrcode-vue
        :margin="3"
        :value="qrcodeValue"
        :size="300"
        level="L"
      ></qrcode-vue>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="submit()">关闭</el-button>
        <el-button type="primary" @click="submit()">我已扫码</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, defineExpose, defineEmits } from 'vue'
import { altWX } from '@/api/user/index'
import QrcodeVue from 'qrcode.vue'
import { useStore } from 'vuex'
const store = useStore()
const dialogVisible = ref(false)
const qrcodeValue = ref('')
const emits = defineEmits(['uppage'])
const setParams = async () => {
  let res = await altWX({ state: store.getters.userInfo.userId })
  qrcodeValue.value = res.data.url
  dialogVisible.value = true
}

const submit = () => {
  dialogVisible.value = false
  emits('uppage')
}

defineExpose({
  setParams
})
</script>
<style lang="scss" scoped></style>
