<template>
  <div class="note col">
    <el-form
      :inline="true"
      :model="queryParams"
      label-width="4.5rem"
      class="seek-form"
    >
      <el-form-item label="课程名称">
        <el-input
          v-model="queryParams.courseName"
          placeholder="请输入课程名称"
        />
      </el-form-item>
      <el-form-item label="课时名称">
        <el-input
          v-model="queryParams.periodName"
          placeholder="请输入课时名称"
        />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="queryParams.creaTime"
          type="date"
          placeholder="选择时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button class="zsbtn" @click="save('all')">保存全部笔记</el-button>
        <el-button class="zsbtn" @click="getList">查找</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="list">
      <div v-if="dataList.length > 0">
        <div class="card" v-for="n in dataList" :key="n.id">
          <div class="row sb">
            <div class="title">
              {{ n.courseName }}
              <span class="subtitle">{{ n.periodName }}</span>
            </div>
            <div class="subtitle">{{ n.createTime }}</div>
          </div>
          <div class="content">
            <div style="width: 100%" class="omit4" v-html="n.content"></div>
          </div>
          <div class="row jue grow1 ma-to-10">
            <el-button
              class="zsbtn"
              @click="save('one', n.id, n.courseName + n.chapterName)"
              >保存笔记
            </el-button>
            <el-button @click="LookOver(n)">查看笔记</el-button>
          </div>
        </div>
      </div>
      <div v-else style="padding: 20px 0">暂无笔记</div>
    </div>
    <div>
      <el-pagination
        layout="prev, pager, next"
        v-show="total > 0"
        :total="total"
        :page-size="queryParams.pageSize"
        @current-change="alterTotal"
      ></el-pagination>
    </div>
    <el-dialog
      width="900px"
      v-model="dialogVisible"
      :before-close="handleClose"
    >
      <template #title>
        <div class="row aic">
          <div class="title">
            {{ showContent.title }}
          </div>
          <div style="margin-left: 1em" class="subtitle">
            {{ showContent.periodName }}
          </div>
        </div>
      </template>
      <div
        v-for="(item, index) in showContent.data"
        :key="item.id"
        class="NoteDetail"
      >
        <!-- <el-input
          v-if="item.edit"
          v-model="item.content"
          :rows="0"
          type="textarea"
          placeholder="输入笔记内容"
        /> -->
        <div v-if="!item.edit" v-html="item.content"></div>
        <div :id="'editor-box' + item.id"></div>
        <div class="row sb" style="margin-top: 10px">
                  <div style="margin-top: 10px">

                  </div>

          <div class="row">
            <el-button
              v-if="item.edit"
              class="zsbtn"
              @click="saveEdit(item, index)"
              >保存</el-button
            >
            <el-button v-if="item.edit" @click="cancel">取消</el-button>
 
            <el-button v-else type="primary" :icon="Edit" @click="editon(item, index)"  circle />
             <el-popconfirm title="是否删除当前笔记内容?" @confirm="detail(item, index)"    @cancel="cancelEvent">
               <template #reference>
            <el-button v-if="!item.edit" type="danger" :icon="Delete"  circle /> 
            </template>
             </el-popconfirm>

              <el-button v-if="item.duration>60" :icon="CaretRight"  round>{{
                moment
                  .duration(item.duration || 0, 'seconds')
                  .format('HH:mm:ss')
              }}</el-button>
              <el-button v-if="item.duration<60" :icon="CaretRight"  round>00:{{
                moment
                  .duration(item.duration || 0, 'seconds')
                  .format('HH:mm:ss')
              }}</el-button>
            <el-button :icon="Calendar"  round>{{ item.createTime }}</el-button>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            class="zsbtn"
            @click="save('one', showContent.id, showContent.title)"
            >保存笔记</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import E from 'wangeditor'
import { ref } from '@vue/reactivity'
import {
  getNoteList,
  exportNote,
  exportAllNote,
  deleteEdit,
  alterNote
} from '@/api/user/index'
import { exportFile } from '../../utils/boloFile'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import {Delete, Edit, CaretRight, Calendar } from '@element-plus/icons'
import { ElMessage } from 'element-plus'

momentDurationFormatSetup(moment)
const queryParams = ref({
  pageNum: 1,
  pageSize: 6
})
const total = ref(0)
const dataList = ref([])
var isEdit = ref(false)
// 弹出窗
const dialogVisible = ref(false)
const showContent = ref({})
var editContent = ref({})
const getList = () => {
  if (queryParams.value.creaTime) {
    queryParams.value.creaTime = moment(queryParams.value.creaTime).format(
      'YYYYMMDD'
    )
  }
  getNoteList(queryParams.value).then((res) => {
    dataList.value = res.rows || []
    total.value = res.total
  })
}
getList()
const alterTotal = (pageNum) => {
  queryParams.value.pageNum = pageNum
  getList()
}
// 重置
const reset = () => {
  queryParams.value = { pageNum: 1, pageSize: 6 }
  getList()
}
// 查看笔记
const LookOver = async (v) => {
  let { courseId, periodId, chapterName, id, periodName } = v
  getNoteList({ courseId, periodId }).then((res) => {
    showContent.value = {
      data: res.rows,
      title: chapterName,
      periodName,
      id
    }
    dialogVisible.value = true
  })
  if (editor != null) {
    beforeDestroy()
  }
}
// Editor实例
let editor
// 处理离开页面切换语言导致 dom 无法被获取
let el
const initEditor = (item, index) => {
  if (editor != null) {
    beforeDestroy()
  }
  el = document.querySelector('#editor-box' + item.id)

  editor = new E(el)
  editor.config.zIndex = 1
  editor.config.height = 200
  editor.config.onchange = function (html) {
    showContent.value.data[index].content = html
  }
  // 菜单栏提示
  editor.config.menus = []
  editor.config.showMenuTooltips = false
  editor.config.showFullScreen = false
  editor.config.menuTooltipPosition = 'down'
  editor.create()
  editor.txt.html(item.content)
}
const beforeDestroy = (item) => {
  // 销毁编辑器
  editor.destroy()
  editor = null
}
// 编辑
const editon = (item, index) => {
  console.log(!editor)
  if (editor) {
    ElMessage({
      message: '请先保存当前编辑内容',
      type: 'error'
    })
    return
  }
  initEditor(item, index)
  editContent.value = { item: { ...item }, index }
  showContent.value.data[index].edit = true
}
// 取消编辑
const cancel = () => {
  beforeDestroy()
  let { item, index } = editContent.value
  showContent.value.data[index].content = item.content
  showContent.value.data[index].edit = false
}
// 保存编辑
const saveEdit = (item, index) => {
  beforeDestroy()
  alterNote({ ...item }).then((res) => {
    if (res.code === 200) {
      ElMessage({
        message: '修改成功',
        type: 'success'
      })
      showContent.value.data[index].edit = false
    } else {
      ElMessage({
        message: res.msg,
        type: 'error'
      })
    }
  })
}
// 删除
const detail = (item, index) => {
  deleteEdit({ id: item.id }).then((res) => {
    if (res.code === 200) {
      LookOver(item)
      // showContent.value.data.split(index, 1)
    }
  })
}
const cancelEvent = () => {
  console.log('取消!')
}
const handleClose = () => {
  dialogVisible.value = false
}
// 保存笔记
const save = (type, id, name) => {
  if (type === 'all') {
    exportAllNote(queryParams.value).then((res) => {
      exportFile(res, '全部笔记', 'application/zip')
    })
  } else if (type === 'one') {
    exportNote({ id: id }).then((res) => {
      exportFile(res, name, 'application/msword;charset=utf-8')
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/rem';

.note {
  align-items: center;
  .seek-form {
    font-size: 24px;
    ::v-deep .el-form--inline {
      margin-right: 10px !important;
    }
    ::v-deep .el-form-item {
      margin-right: 5px !important;
    }
  }
  .el-select {
    width: rem(140);
  }
  .list {
    .card {
      width: rem(1100);
      padding: rem(10);
      margin-bottom: rem(20);
      background: #f3f5f7;
      border-radius: rem(10);
      .content {
        padding: rem(20);
        font-weight: 400;
        color: #666666;
        border-radius: rem(10);
        background: #ffffff;
      }
    }
  }
  .zsbtn {
    background: #293e73;
    color: white;
  }

  ::v-deep .el-dialog {
    border-radius: rem(10);
  }
  .NoteDetail {
    background: #f3f5f7;
    word-break: break-all;
    padding: rem(20);
    margin-bottom: 2px;
    border-radius: rem(10);
    padding-bottom: rem(10);
  }
}
</style>
