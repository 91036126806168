<template>
  <el-dialog v-model="dialogVisible" title="修改密码" width="500px">
    <!-- 旧密码修改 -->
    <el-form
      v-if="alterType === 1"
      :model="alterParam"
      ref="alterForm"
      :rules="rules"
      label-width="7rem"
    >
      <el-form-item label="请输入密码" prop="oldPassword">
        <el-input v-model="alterParam.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="请输入新密码" prop="newPassword">
        <el-input v-model="alterParam.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="请确认新密码" prop="chePassword">
        <el-input v-model="alterParam.chePassword"></el-input>
      </el-form-item>
    </el-form>
    <!-- 手机号修改 -->
    <el-form v-if="alterType === 2" :model="alterPhoneData" label-width="7rem">
      <el-form-item label="请输入手机号" prop="addressee">
        <el-input v-model="alterPhoneData.phone" disabled></el-input>
        <auth-code
          text="发送"
          ref="code"
          class="auth-code"
          btnClass="code-btn"
          second="60"
          @start="serdCode(alterPhoneData.phone)"
        ></auth-code>
      </el-form-item>
      <el-form-item label="请输入验证码" prop="eamilCode">
        <el-input v-model="alterPhoneData.code" />
      </el-form-item>
      <el-form-item label="请输入新密码" prop="newPassword">
        <el-input v-model="alterPhoneData.newPassword"></el-input>
      </el-form-item>
    </el-form>
    <!-- 邮箱修改 -->
    <el-form v-if="alterType === 3" ref="alterEmail" label-width="7rem">
      <el-form-item label="请输入邮箱" prop="addressee">
        <el-input v-model="alterEmailData.addressee" disabled></el-input>
        <auth-code
          text="发送"
          ref="code"
          class="auth-code"
          btnClass="code-btn"
          second="60"
          @start="serdCode(alterEmailData.addressee)"
        ></auth-code>
      </el-form-item>
      <el-form-item label="请输入验证码" prop="eamilCode">
        <el-input v-model="alterEmailData.eamilCode" />
      </el-form-item>
      <el-form-item label="请输入新密码" prop="newPassword">
        <el-input v-model="alterEmailData.newPassword"></el-input>
      </el-form-item>
    </el-form>

    <div class="row text-right jue">
      <a>
      <div style="margin-right: 1rem" @click="alterType = 1">旧密码修改</div>
      </a>
      <a>
      <div style="margin-right: 1rem" @click="alterType = 2">手机号修改</div>
      </a>
      <a>
      <div @click="alterType = 3">邮箱修改</div>
      </a>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" plain @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submit()">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { getPhoneCode, checkPhoneCode } from '@/api/login'
import {
  alterPass,
  getEmailCodeEnd,
  checkEmail,
  alterPasst
} from '@/api/user/index'
import AuthCode from '@/components/AuthCode'
import { regexPhone, regexEmail } from '../../../common/regexp'

const alterForm = ref(null)
const alterPhone = ref(null)
const alterEmail = ref(null)

const store = useStore()
const dialogVisible = ref(false)
const alterType = ref(1)

const alterParam = ref({})
const alterEmailData = ref({
  addressee: store.getters.userInfo.email,
  eamilCode: ''
})
const alterPhoneData = ref({
  phone: store.getters.userInfo.phonenumber,
  code: ''
})

const rules = reactive({
  oldPassword: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur'
    }
  ],
  newPassword: [
    {
      required: true,
      message: '请输入新密码',
      trigger: 'blur'
    },
    {
      min: 6,
      max: 18,
      message: '最少6位最多18位',
      trigger: ['blur', 'change']
    }
  ],
  chePassword: [
    {
      required: true,
      message: '请输入确认密码',
      trigger: 'blur'
    },
    {
      validator: (rule, val, callback) => {
        val !== alterParam.value.newPassword
          ? callback(new Error('两次输入不一致'))
          : callback()
      },
      trigger: ['blur', 'change']
    }
  ]
})

const serdCode = async (value) => {
  if (regexPhone.test(value)) {
    const { data } = await getPhoneCode({ phone: value })
    if (data) {
      ElMessage({
        message: '发送成功',
        type: 'success'
      })
    }
  } else if (regexEmail.test(value)) {
    const { data } = await getEmailCodeEnd({ addressee: value })
    if (data) {
      ElMessage({
        message: '发送成功',
        type: 'success'
      })
    }
  } else {
    ElMessage({
      message: '还没有绑定',
      type: 'error'
    })
  }
}

const setParams = () => {
  dialogVisible.value = true
}
const tongguoyanzheng = (password) => {
  alterPasst({ password: password }).then(({ code }) => {
    if (code === 200) {
      ElMessage({
        message: '修改成功，请重新登录',
        type: 'success'
      })
      dialogVisible.value = false
      store.dispatch('user/logout')
    } else {
      ElMessage({
        message: res.msg,
        type: 'error'
      })
    }
  })
}
const submit = () => {
  if (alterType.value === 1) {
    alterForm.value.validate((value) => {
      if (value) {
        alterPass(alterParam.value).then(({ code }) => {
          if (code === 200) {
            ElMessage({
              message: '修改成功，请重新登录',
              type: 'success'
            })
            dialogVisible.value = false
            store.dispatch('user/logout')
          }
        })
      }
    })
  } else if (alterType.value === 2) {
    checkPhoneCode(alterPhoneData.value).then((res) => {
      if (res.data) {
        tongguoyanzheng(alterPhoneData.value.newPassword)
      } else {
        ElMessage({
          message: '验证码不正确',
          type: 'error'
        })
      }
    })
  } else if (alterType.value === 3) {
    checkEmail({
      ...alterEmailData.value
    }).then((res) => {
      if (res.data) {
        tongguoyanzheng(alterEmailData.value.newPassword)
      } else {
        ElMessage({
          message: '验证码不正确',
          type: 'error'
        })
      }
    })
  }
}

defineExpose({
  setParams
})
</script>
<style lang="scss" scoped></style>
