<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <!-- 课程表 -->
    <el-tab-pane label="课程表" name="first">
      <div class="classSchedule row">
        <el-calendar ref="calendar"  v-model="queryTime" >
           <template #header="{ date }">
              <span>{{ date }}</span>
                <el-button-group>
                <el-button color="#6e7eb8" plain size="small" @click="selectDateon('prev-month')"
                  >上个月</el-button
                >
                <el-button color="#6e7eb8" plain size="small" @click="selectDateon('today')">今天</el-button>
                <el-button color="#6e7eb8" plain size="small" @click="selectDateon('next-month')"
                  >下个月</el-button
                >
              </el-button-group>
             </template>
        <template #dateCell="{ data }">
        <p :class="data.isSelected ? 'is-selected' : ''">
          {{ data.day.split('-').slice(2).join('-') }}
        </p>
      </template>
     </el-calendar>
        <div class="curriculum col">
          <div class="title">今日课程</div>
          <div v-if="coursesList.length > 0">
            <div class="list row">
              <coursesCard
                v-for="i in coursesList"
                @click="lookVideoday(i)"
                :key="i.id"
                :isbottom="false"
                :image="i.courseLogo"
                :title="i.courseName"
              ></coursesCard>
            </div>
          </div>
          <div v-else class="lack">
            <svg-icon icon="zanwujilu" style="font-size: 200px" />
            没有学习记录
          </div>
          <div class="row alc juc">
            <el-pagination
              style="margin-top: 20px"
              layout="prev, pager, next"
              :page-size="queryParams.pageSize"
              @current-change="alterTotal"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <!-- 我的课程 -->
    <el-tab-pane label="我的课程" name="second">
      <div class="myCourses">
        <div>
          分类：
          <el-radio-group v-model="classActive">
            <el-radio-button
              fill="#293E73"
              v-for="i in classList"
              @change="clickTabs(i.i)"
              :key="i.i"
              :label="i.t"
            ></el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="coursesList.length > 0" class="row">
          <courses-card
            @click="lookVideo(item)"
            v-for="item in coursesList"
            :key="item.id"
            :image="item.courseLogo"
          >
            <template #container>
              <div class="container">
                <div class="title" style="font-size: 18px">
                  {{ item.courseName }}
                </div>
                <div style="font-size: 15px;margin-top: 5px;">开通时间：{{ item.createTime }}</div>
                <div style="font-size: 15px;">到期时间：{{ item.expireTime }}</div>
                <el-progress
                  :percentage="
                    ((item?.periodStudy || 0) / (item?.periodTotal || 0)) *
                      100 <
                    100
                      ? parseFloat(
                          (
                            ((item?.periodStudy || 0) /
                              (item?.periodTotal || 0)) *
                            100
                          ).toFixed(2)
                        )
                      : 100
                  "
                  color="#284E91"
                />
              </div>
            </template>
          </courses-card>
        </div>
        <div v-else class="lack">
          <svg-icon icon="zanwujilu" style="font-size: 200px" />
          暂无记录
        </div>
        <div class="row" style="justify-content: center">
          <el-pagination
            style="margin: 20px auto"
            :hide-on-single-page="false"
            layout="prev, pager, next"
            @current-change="alterTotal"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </el-tab-pane>
    <!-- 收藏课程 -->
    <el-tab-pane label="收藏课程" name="third">
      <div v-if="coursesList.length > 0" class="srarList row">
        <div
          v-for="item in coursesList"
          :key="item.id"
          @click="router.push('/curriculum/details/' + item.courseId)"
        >
          <courses-card
            v-if="item.sort"
            :image="item.courseLogo"
            :title="item.courseName"
          >
            <template #container>
              <div class="container">
                <div class="title row" style="font-size: 18px">
                  <span> {{ item.courseName }}</span>
                  <el-popconfirm
                    title="确定取消收藏吗?"
                    @confirm="delectCollect(item.courseId)"
                  >
                    <template class="icon row" #reference>
                      <el-icon :size="24"      color="#ff9800"
                        ><star-filled /></el-icon
                      ><span>已收藏</span>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </template>
          </courses-card>
          <coursesCard
            v-else
            :key="item.id"
            :isbottom="false"
            :image="item.courseLogo"
            :title="item.courseName"
          ></coursesCard>
        </div>
      </div>
      <div v-else class="lack">
        <svg-icon icon="zanwujilu" style="font-size: 200px" />
        还没有收藏课程快去收藏吧
      </div>

      <div class="row" style="justify-content: center">
        <el-pagination
          style="margin: 20px auto"
          :hide-on-single-page="false"
          layout="prev, pager, next"
          @current-change="alterTotal"
          :total="total"
        ></el-pagination>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script setup>
import { ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { StarFilled } from '@element-plus/icons'
import { ElMessage } from 'element-plus'
import coursesCard from '@/components/CoursesCard'
import { setItem } from '../../utils/storage'
import {
  getMyCourseList,
  getLearnRecordList,
  getcollectList,
  delcollect
} from '@/api/user'
import moment from 'moment'
moment.locale('zh-cn')
const router = useRouter()
const activeName = ref('first')
const queryTime = ref(new Date())
const classList = ref([
  { i: 1, t: '在学课程', c: true },
  { i: 2, t: '过期课程', c: false },
  { i: 3, t: '下架课程', c: false }
])
var ispage = '课程表'
const classActive = ref(classList.value[0].t)
const coursesList = ref([])
const total = ref(0)
const queryCourlist = ref({
  isPutaway: 1,
  overdue: 0
})
const queryParams = ref({
  pageSize: 6,
  pageNum: 1,
  day: moment().format('YYYYMMDD')
})

const calendar = ref()
const selectDateon = (val) => {
  console.log(val)
  calendar.value.selectDate(val)
}

const clickTabs = (i) => {
  console.log(i)
  if (i === 1) queryCourlist.value = { isPutaway: 1, overdue: 0 }
  if (i === 3) queryCourlist.value = { isPutaway: 0 }
  if (i === 2) queryCourlist.value = { overdue: 1 }
  queryParams.value = {
    pageSize: 9,
    pageNum: 1
  }
  getmyCourseList()
}
// 课程列表
const getmyCourseList = () => {
  getMyCourseList({ ...queryCourlist.value, ...queryParams.value }).then(
    (res) => {
      total.value = res?.total || 0
      coursesList.value = res.rows
    }
  )
}
// 学习记录
const getLearnlist = () => {
  getLearnRecordList(queryParams.value).then((res) => {
    total.value = res?.total || 0
    coursesList.value = res.rows
  })
}
// 收藏
const getcollect = () => {
  getcollectList(queryParams.value).then((res) => {
    total.value = res?.total || 0
    coursesList.value = res.rows
  })
}
// 取消收藏
const delectCollect = (courseId) => {
  delcollect(courseId)
  getcollect()
}

getLearnlist()
const handleClick = (tab) => {
  total.value = 0
  coursesList.value = []
  ispage = tab.props.label
  if (tab.props.label === '我的课程') {
    queryParams.value = {
      pageSize: 9,
      pageNum: 1
    }
    getmyCourseList()
  } else if (tab.props.label === '课程表') {
    queryParams.value = {
      pageSize: 6,
      pageNum: 1,
      day: moment().format('YYYYMMDD')
    }
    getLearnlist()
  } else if (tab.props.label === '收藏课程') {
    queryParams.value = {
      pageSize: 9,
      pageNum: 1
    }
    getcollect()
  }
}
const alterTotal = (pageNum) => {
  queryParams.value.pageNum = pageNum
  if (ispage === '我的课程') {
    getmyCourseList()
  } else if (ispage === '课程表') {
    getLearnlist()
  } else if (ispage === '收藏课程') {
    getcollect()
  }
}
watchEffect(() => {
  if (queryTime.value && activeName.value === 'first') {
    queryParams.value.day = moment(queryTime.value).format('YYYYMMDD')
    getLearnlist()
  }
})
const lookVideo = (params) => {
  if(queryCourlist.value.isPutaway!=null&&!queryCourlist.value.isPutaway){
    ElMessage({
      message: '当前课程已下架',
      type: 'info'
    })
    return
  }
  if (!queryCourlist.value.overdue) {
    const a = router.resolve({
      path: '/learn/video'
    })
    setItem('videoInfo', { ...params })
    window.open(a.href, '_parent')
  } else {
    if (queryCourlist.value.isPutaway) return
    ElMessage({
      message: '当前课程已过期',
      type: 'info'
    })
    router.push({
      path: '/curriculum/details/' + params.courseId
    })
  }
}
const lookVideoday = (params) => {
  console.log(params);
  if(params.isPutaway==0){
        ElMessage({
      message: '当前课程已下架',
      type: 'info'
    })
    return
  }
  if(params.overdue==1){
        ElMessage({
      message: '当前课程已过期',
      type: 'info'
    })
      router.push({
      path: '/curriculum/details/' + params.courseId
    })
        return
  }
    const a = router.resolve({
      path: '/learn/video'
    })
    setItem('videoInfo', { ...params })
    window.open(a.href, '_parent')

  }
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.classSchedule {
  justify-content: space-between;
  .el-calendar {
    width: rem(624);
  }
  .curriculum {
    width: rem(444);
    height: 614px;
    border: 1px solid #eeeeee;
    > .title {
      text-align: center;
    }
    div {
      .list {
        width: 100%;
        flex-wrap: wrap;
        .el-card {
          margin: 10px;
          width: 200px;
          ::v-deep .title {
            line-height: 1;
          }
          ::v-deep .showImage {
            height: 110px;
          }
          .el-pagination {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
.myCourses {
  ::v-deep .el-radio-button__original-radio:checked + .el-radio-button__inner {
    background: #293e73;
    border-color: #293e73;
  }

  .row {
    flex-wrap: wrap;
    .el-card {
      width: rem(340);
      margin: rem(10);
    }
    .container {
      padding: rem(20);
      .title {
        font-size: rem(18);
        line-height: 1.5;
      }
    }
  }
}
.srarList {
  flex-wrap: wrap;
  .icon {
    font-size: 12px;
    font-weight: bold;
    color: #293e73;
    align-items: center;
    line-height: 50px;
  }
  .el-card {
    width: rem(340);
    margin: rem(10) rem(15);
    .container {
      padding: rem(20);
    }
    .title {
      justify-content: space-between;
    }
  }
}
// .is-today{
//     color: var(--el-color-white);
// }
.el-calendar{
  --el-calendar-selected-bg-color: #6e7eb8
}
  .is-selected {
        color: #ffffff;
  }

</style>
