<template>
  <div class="credit">
    <div class="credit_header">
      <div class="title">
        积分规则：<span class="subtitle"
          >首次完整播放1个视频，记录为1个课时，即获得1个学分。重复播放视频，不获得学分。</span
        >
      </div>
      <div class="title row sb">
        <div>我的学分： 
          <span class="subtitle">{{ fractionNum }}</span></div>
        <div class="can" style="font-weight: 900;font-size: 18px;">
          <a> <span     :class="{ acative: isAddition == 1 }"
           @click="staic(1)">获取记录</span></a>
           |
          <a>
          <span     :class="{ acative: isAddition == 0 }"
           @click="staic(0)">支出记录</span>
          </a>  
        </div>
      </div>
    </div>
    <el-table :data="columnList" stripe style="width: 100%">
      <el-table-column
        v-for="item in column"
        :key="item.i"
        :prop="item.childrenName"
        :label="item.t"
        align="center"
      />
    </el-table>
    <div class="row juc">
      <el-pagination
        class="ma-tb-30"
        layout="prev, pager, next"
        @current-change="alterTotal"
        :page-size="queryParams.pageSize"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { getFractionList } from '@/api/user/index'
import { useStore } from 'vuex'
const store = useStore()
store.dispatch('user/getUserInfo')  
const fractionNum = ref(store.getters.userInfo.fraction)
const total = ref(0)
const queryParams = ref({
  pageNum: 1,
  pageSize: 9
})
const column = ref([
  { i: 63, t: '课程名称', childrenName: 'courseName' },
  { i: 15, t: '课时名称', childrenName: 'periodName' },
  { i: 51, t: '获取时间', childrenName: 'createTime' },
  { i: 45, t: '获取数量', childrenName: 'fractionNum' }
])
const isAddition = ref(1)
const columnList = ref([])
const getList = () => {
  getFractionList({ isAddition: isAddition.value, ...queryParams.value }).then(
    (res) => {
      columnList.value = res.rows || []
      total.value = res.total
    }
  )
}
const staic = (get) => {
  isAddition.value = get
  getList()
}
const alterTotal = (pageNum) => {
  queryParams.value.pageNum = pageNum
  getList()
}
getList()
</script>
<style lang="scss" scoped>
@import '@/common/rem';
.credit {
  .credit_header {
    margin: rem(30) 0;
    .title,
    .subtitle {
      line-height: 1.5;
      font-size: rem(14);
      .acative {
          color: #293e73;
        }
    }
    .subtitle {
      font-size: rem(14);
    }
  }
}
</style>
